import React from 'react';
import styled from 'styled-components';

function ProductCard({ header, text, reversed, children }) {
    function setOrder(reversed) {
        return reversed ? <>
            <ImageWrapper>
                {children}
            </ImageWrapper>
            <DescriptionWrapper>
                <h1>{header}</h1>
                <p>{text}</p>
            </DescriptionWrapper></>
            :
            <> <DescriptionWrapper>
                <h1>{header}</h1>
                <p>{text}</p>
            </DescriptionWrapper>
                <ImageWrapper>
                    {children}
                </ImageWrapper>
            </>;
    }

    return (
        <InfoCardWrapper reversed={reversed}>
            {setOrder(reversed)}
        </InfoCardWrapper>
    );
}

export default ProductCard;

const InfoCardWrapper = styled.div`
display: flex;
width: 100%;
max-width: 1300px;
display: flex;
overflow: hidden;
margin-bottom: 30px;
border-radius: 25px;
background-color: rgba(224, 224, 224, 0.55);
-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);


@media screen and (max-width :900px){
    flex-wrap: ${props => props.reversed ? 'wrap' : 'wrap-reverse'};
}

`;

const ImageWrapper = styled.div`
 min-width: 370px;
 padding: 15px;
 @media screen and (max-width :900px){
    min-width: 100%;
 }
`;

const DescriptionWrapper = styled.div`
opacity: 1;
max-width: 1200px;
min-width: 500px;
padding: 40px 200px;
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
h1{
    color: black;
    /* padding:30px 20px; */
    font-size: 40px;
}
p{
    font-size: 16px;
}
@media screen and (max-width :900px){
    padding: 20px;
    min-width: 100px;
   h1{
    font-size: 30px;
   } 
   p{
    padding: 5px;
   }
 }
`;