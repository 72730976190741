export const rollerBlindsData = {
    headerInfo: {
        header: 'Ρολοκουρτίνες - Ρόλλερ',
        text: 'Πρόκειται για υφάσματα  τα οποία είναι τυλιγμένα σε ένα οριζόντιο αλουμινένιο άξονα και με τη βοήθεια ενός  χειριστηρίου (χειροκίνητο ή ηλεκτρικό ), περιστρέφετε ο άξονας προς τη μία ή προς την άλλη πλευρά, με αποτέλεσμα το ύφασμα να ανεβαίνει ή να κατεβαίνει .Κατασκευάζονται σε μεγάλη ποικιλία σχεδίων και υφασμάτων, μπορούμε να εκτυπώσουμε οποιοδήποτε θέμα ή λογότυπο και  κατατάσσονται  στις εξής κατηγορίες:'
    },
    monochrome: {
        header: 'Μονόχρωμα ( απλά )',
        text: 'Πρόκειται για μια κατηγορία ρόλλερ με μεγάλη γκάμα χρωμάτων  από μονόχρωμα υφάσματα σκίασης. Τα χρησιμοποιούμε σε γραφεία, δωμάτια, επαγγελματικούς χώρους κλπ.',
    },
    screen: {
        header: 'Screen',
        text: 'Πρόκειται για μία κατηγορία ρόλλερ όπου το ύφασμα είναι διάτρητο. Τα  χρησιμοποιούμε για να μας προσφέρουν σκίαση στον χώρο μας αλλά ταυτόχρονα μας επιτρέπει την ορατότητα προς τα  έξω',
    },
    blackout: {
        header: 'Βlackout',
        text: 'Πρόκειται για μία κατηγορία ρόλλερ όπου το ύφασμα το οποίο χρησιμοποιούμε  μας δίνει την δυνατότητα να έχουμε πλήρη (ολική) συσκότιση του χώρου. Το χρησιμοποιούμε σε υπνοδωμάτια, σε δωμάτια ξενοδοχείων, σε αίθουσες προβολών, σε αίθουσες συνεδριάσεων κλπ.',
    },
    zebra: {
        header: 'Zebra ( double roller)',
        text: 'Πρόκειται για μία κατηγορία ρόλλερ όπου το ύφασμα έχει σταθερές εναλλαγές από διάφανες ρίγες ( διαπερατά τμήματα)  και σχέδια ( μη διαπερατά τμήματα). Έτσι δίνεται η δυνατότητα   να σταματήσουμε τα διπλά ρόλλερ στο σημείο που επιθυμούμε, επιτρέποντας  να βλέπουμε έξω ή όχι.',
    },
    embrime: {
        header: 'Εμπριμέ',
        text: 'Πρόκειται για μια κατηγορία ρόλλερ από διακοσμητικά υφάσματα σκίασης όπου πετυχαίνουμε την σκίαση, την αισθητική και τη διακόσμηση του χώρου. Τα χρησιμοποιούμε σε αίθουσες εκδηλώσεων, σε σαλόνια σπιτιού κλπ.',
    }
};

export const venetika_stor = {
    headerInfo: {
        header: 'Βενετικά στόρια (venetian blinds)',
        text: 'Τα βενετικά στόρια είναι οι γνωστές οριζόντιες περσίδες αλουμινίου ή ξύλου. Έχουν δύο χειριστήρια το ένα τα ανεβάζει ή κατεβάζει τις περσίδες και το άλλο τις περιστρέφει στη φορά που επιθυμούμε'

    },
    persides_alouminiou: {
        header: 'Περσίδες αλουμινίου',
        text: 'Κατασκευάζονται συνήθως από οριζόντιες περσίδες πλάτους  25mm, ενώ υπάρχουν οι πιο στενές 16mm αλλά και πιο φαρδιές 50mm. Μπορεί η κάθε περσίδα να είναι σε ματ ή γυαλιστερό χρώμα, διάτρητη ή μη, ενώ βγαίνουν και σε ξύλινες αποχρώσεις'
    },
    ksyllinies_persides: {
        header: 'Ξύλινες περσίδες',
        text: 'Κατασκευάζονται από εξαιρετικής ποιότητας ξύλο πλάτους 35mm καθώς και από πιο φαρδιές 50mm. Δημιουργούν μια ζεστή και παραδοσιακή ατμόσφαιρα στο χώρο που θα τοποθετηθούν'
    }
};

export const kathetes_persides = {
    header: 'Κάθετες περσίδες ( vertical blinds) ',
    text: 'Κατασκευάζονται από  υφασμάτινες λωρίδες συνήθως 127mm αλλά υπάρχουν και οι πιο στενές των 89mm. Η  κίνησή τους γίνεται πάνω  σε αλουμινένιο μηχανισμό σε οριζόντια φορά  με τη χρήση ενός χειριστηρίου,  ενώ  με ένα δεύτερο χειριστήριο πετυχαίνουμε την κάθετη περιστροφή  στον εαυτό τους. Πρόκειται για ένα διαχρονικό σύστημα εσωτερικής σκίασης που μπορεί να κατασκευαστεί μέσα από μια μεγάλη ποικιλία υφασμάτων και χρωμάτων  και να ικανοποιήσει την οποιαδήποτε ανάγκη.'
};

export const panel = {
    header: 'Πάνελ ',
    text: 'Η  σκίαση με πάνελ είναι ένας μοντέρνος τρόπος σκίασης για μεγάλα ανοίγματα όπου τα υφάσματα (πάνελ) κινούνται σε οριζόντια φορά πάνω σε αλουμινένιο μηχανισμό τριών, τεσσάρων ή πέντε δρόμων. Δίνεται η δυνατότητα τα φύλλα να ανοίγουν είτε σε μια μεριά, είτε στις δύο  (τύπου αυλαία ) ή ακόμα και ασύμμετρα. Κατασκευάζονται σε μεγάλη ποικιλία υφασμάτων και μπορούμε να επιλέξουμε άνετα συνδυασμό χρωμάτων στα υφάσματα. Μπορούν να τοποθετηθούν σε οικίες γραφεία, επαγγελματικούς χώρους, ξενοδοχεία.'
};

export const plise = {
    header: 'Πλισέ',
    text: 'Το σύστημα πλισέ είναι ένας ιδιαίτερος τρόπος σκίασης που τον χρησιμοποιούμε σε “ιδιόμορφα” παράθυρα όπως κυκλικά, ημικυκλικά,  τριγωνικά ή πολυμορφικά. Η κίνηση τους μπορεί να είναι προς τη μία μεριά αλλά ακόμα να γίνεται και αμφίπλευρα. Προσφέρουν ηλιοπροστασία και δίνουν μια διακοσμητική νότα στο χώρο.'
};

export const ptyssomenes_portes = {
    header: 'Πτυσσόμενες πόρτες πλαστικές από PVC',
    text: 'Οι πτυσσόμενες πόρτες τύπου φυσούνα ή φυσαρμόνικα αποτελούν  την ιδανική λύση για χώρους οπού δεν είναι δυνατόν να τοποθετηθούν ξύλινες πόρτες. Λόγω κατασκευής τους καταλαμβάνουν περιορισμένο χώρο και μπορούμε να τις χρησιμοποιήσουμε και για διαχωρισμό μεγάλων ενιαίων χώρων.Κατασκευάζονται από φύλλα PVC μεγάλης αντοχής. Μπορούμε να επιλέξουμε ανάμεσα από μεγάλη ποικιλία χρωμάτων ( μονόχρωμα ή απομιμήσεις  ξύλου ), με τζαμάκια ή χωρίς, όπως και να κλείνουν με απλό μαγνήτη  ή κανονική κλειδαριά. Οι ποιοτικές πτυσσόμενες πόρτες κατασκευάζονται ακριβώς στην διάσταση που επιθυμούμε  Υπάρχει η δυνατότητα στις μεγάλες διαστάσεις  ( άνω από 1,60m ) να κατασκευαστούν ως δίφυλλες.'
};

export const sites = {
    header: 'Σίτες',
    text: 'Οι αντικουνουπικές  σίτες  κατασκευάζονται από ύφασμα άριστης ποιότητας και ανάλογα με τις ανάγκες μπορούν να διατεθούν με τους εξής μηχανισμούς. Ορίζοντας κίνησης,  κάθετης κίνησης, ανοιγόμενες, συρόμενες / επάλληλες δίφυλλες, σταθερά τελάρα.'
};

export const systimata_kourtinon = {
    kourtinoverges: {
        header: 'Κουρτινόβεργες',
        text: 'Μεγάλη ποικιλία από  ξύλινες  και μεταλλικές  κουρτινόβεργες.'
    },
    sidirodromoi: {
        header: 'Σιδηρόδρομοι',
        text: 'Σιδηρόδρομοι με διάφορους μηχανισμούς, με μονό, διπλό η τριπλό οδηγό που  μπορούν να καλύψουν κάθε ανάγκη για το κρέμασμα των κουρτινών. '
    },
    sidirodromoi_up_down: {
        header: 'Σιδηρόδρομοι ανέβα – κατέβα',
        text: 'Ο ευκολότερος τρόπος για το κρέμασμα της κουρτίνας. Χωρίς σκάλα με μια απλή κίνηση. Διατίθεται με  μονό, διπλό, τριπλό ή και συνδυασμό αυτών οδηγό.'
    }
};