import React from 'react';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import ProductCard from '../../components/elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import { systimata_kourtinon } from '../../Data/pagesTexts';
import { Helmet } from 'react-helmet';
import { imageWrapper } from './products.module.scss';
function Kourtines() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Olkis | Kουρτινόβεργες, Σιδηρόδρομοι, Σιδηρόδρομοι ανέβα – κατέβα'}</title>
                <html lang={'el'} />
                <meta
                    name="description"
                    content={'Μεγάλη ποικιλία από ξύλινες και μεταλλικές κουρτινόβεργες. Σιδηρόδρομοι με διάφορους μηχανισμούς, με μονό, διπλό η τριπλό οδηγό που μπορούν να καλύψουν κάθε ανάγκη για το κρέμασμα των κουρτινών. Ο ευκολότερος τρόπος για το κρέμασμα της κουρτίνας. Χωρίς σκάλα με μια απλή κίνηση. Διατίθεται με μονό, διπλό, τριπλό ή και συνδυασμό αυτών οδηγό.'}
                />
            </Helmet>
            <Layout>
                <MainView>
                    {/* <About backgroundColor={false} header={rollerBlindsData.headerInfo.header} text={rollerBlindsData.headerInfo.text} /> */}
                    <ProductCard header={systimata_kourtinon.kourtinoverges.header} text={systimata_kourtinon.kourtinoverges.text}  >
                        <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/kourtinoksyla/kourtinoksylo.jpg' alt="asdasdasdas" />
                    </ProductCard>
                    <ProductCard reversed header={systimata_kourtinon.sidirodromoi.header} text={systimata_kourtinon.sidirodromoi.text} >
                        <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/kourtinoksyla/σιδηρόδρομος 1.jpg' alt="asdasdasdas" />
                    </ProductCard>
                    <ProductCard header={systimata_kourtinon.sidirodromoi_up_down.header} text={systimata_kourtinon.sidirodromoi_up_down.text} >
                        <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/kourtinoksyla/συστημα τύπου dimico.jpg' alt="asdasdasdas" />
                    </ProductCard>

                </MainView>
            </Layout>
        </>
    );
}

export default Kourtines;

const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
background: linear-gradient(360deg, #4d4d55 0%, #f5f5f5 100%);

`;